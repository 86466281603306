import img1 from "./assets/image1.png";
import img12 from "./assets/image12.png";
import img14 from "./assets/image14.png";
import img2 from "./assets/image2.png";



import img4 from "./assets/image4.png";
import logo from "./assets/logo.png";

function App() {
    return (
        <div class="container">
            <div class="logo">
                <img src={logo} alt="logo" />
                <h1>Help Content for Escape Earth - 3d
                </h1>
            </div>
            <h3 class="version">Edition of 27.11.2024</h3>
            <div className="d-md-flex gap-3 mt-3">
                <div>
                    <h2 class="title my-3">Game Introduction</h2>
                    <p class="content">
                        Escape Earth is a thrilling endless runner game set in a post-apocalyptic world filled with danger and destruction. As Earth falls into chaos, players must navigate through a crumbling cityscape in a desperate attempt to escape.
                    </p>

                    <h2 className="title mt-4">Daily Resets Follow UTC Time</h2>
                    <ul className="mt-2">
                        <li>This game follows <b>UTC time</b> (Coordinated Universal Time) for all daily resets. This means every 24 hours, the game starts fresh at the same time for everyone around the world.</li>
                        <li>By using UTC, the game ensures fairness and consistency, no matter where you are. To stay on track, you can check the current UTC time online and plan accordingly. Enjoy a fresh start every day!</li>
                    </ul>
                </div>
            </div>


            <div className="d-md-flex gap-3 mt-5">
                <div>
                    <h2 className="title">How to play</h2>
                    <p className="content mt-3">
                        In this game, you control a runner who moves forward automatically. Your goal is to collect points as you run and avoid obstacles.
                    </p>
                    <ul className="mt-2">
                        <li><span>Collect Points:</span>Points are collected along the way, so try to grab as many as you can to increase your score.</li>
                        <li><span>Lives:</span>You have three lives. Be careful! If you hit an obstacle, you lose one life.</li>
                        <li><span>Keep Running:</span>The game doesn't stop—your character will keep running, and the speed will increase. Stay alert and react quickly!</li>
                    </ul>
                </div>
                <img src={img4} alt="guide" />
            </div>
            <div className="d-md-flex gap-3 mt-5">
                <div>
                    <h2 className="title">Earn more Coins</h2>
                    <ul>
                        <li>In this section, you will earn more points for completing activities.
                        </li>
                        <li>Boost your points by watching videos on YouTube, following us on Twitter, and joining our Telegram channel!
                        </li>
                    </ul>
                </div>
                <img src={img14} alt="guide" />
            </div>
            <div className="d-md-flex gap-3 mt-5">
                <div>
                    <h2 className="title">Daily Reward</h2>
                    <p className="content">Play daily and claim your reward points.</p>
                </div>
                <img src={img1} alt="guide" className="ms-auto" />
            </div>
            <div className="d-md-flex gap-3 mt-5">
                <div>
                    <h2 className="title">Daily Challenge</h2>
                    <p className="content">Swipe in the correct direction to reach the exit. Complete the challenge within 30 seconds to earn bonus points.</p>
                </div>
                <img src={img12} alt="guide" />
            </div>
            <div className="d-md-flex gap-3 mt-5">
                <div>
                    <h2 className="title">Wallet</h2>
                    <p className="content">You can connect your wallet and claim your tokens once you reach a claimable threshold. </p>
                </div>
                <img src={img2} alt="guide" />
            </div>
            {/* <div className="mt-4">
                <div>
                    <h2 className="title">Steak and earn points </h2>
                    <ul>
                        <li>
                            The staking module will be enabled if you have collected 50k game tokens. Once eligible, you will be able to stake your in-game tokens and earn daily returns. The amount of tokens staked will contribute towards the TVL (Total Value Locked) of the game. You will have the following options to stake your tokens:
                            <ul>
                                <li>Stake for 30 days: Get 0.1% daily return</li>
                                <li>Stake for 60 days: Get 0.2% daily return</li>
                                <li>Stake for 90 days: Get 0.4% daily return</li>
                                <li>Stake for 180 days: Get 0.8% daily return</li>
                            </ul>
                        </li>
                        <li>
                            If you unstake your tokens before the staking period ends, you will only receive the principal amount, reduced by 10%.
                        </li>
                    </ul>
                </div>
                <div className="d-md-flex gap-3">
                    <img src={img8} alt="guide" />
                    <img src={img10} alt="guide" />
                </div>
            </div>
            <div className="d-md-flex gap-3 mt-5">
                <div>
                    <h2 className="title">Haptic effect</h2>
                    <p className="content">Integration of haptic feedback which will result in vibrations on every tap.
                    </p>
                </div>
                <img src={img9} alt="guide" />
            </div> */}
        </div>
    );
}

export default App;
